<template>
  <div>
    <ReportIndex
      :fstId="`riderReports_${getId}_`"
      :pageTitle="$t('components.reportManagement.headline')"
      :endpoint="getEndpoint"
      :qso="getEndpointOptions"
    />
  </div>
</template>

<script>
import ReportIndex from '@/composites/report/index/Index.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewRiderUserTrips',

  components: {
    ReportIndex,
  },

  computed: {
    getId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return useEndpoints.report.index()
    },
    getEndpointOptions() {
      return {
        append: `&user=${this.$route.params.id}`,
        prepend: `?`,
      }
    },
  },
}
</script>
